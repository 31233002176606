import ClipboardJS from 'clipboard';
import seedrandom from 'seedrandom';

(function(win) {
  win.app = win.app || {};

  win.app.generatePassword = {
    $: {},

    init: function() {
      this.numWords = 5;
      this.wordSeparators = ",.-";
      this.numPadding = 2;
      this.paddingChars = "!@#";

      this.numbers = "1234567890";

      this.chosenWords = [];
      this.finalPassword = "";

      this.$.generatedPassword = $("#generated-password");
      this.$.generatedPassword.val('');

      this.bindButtons();
      this.readValuesFromLocalStorage();
    },

    insertRandomNumber: function(input) {
      const rng = seedrandom();
      const randomNumber = this.numbers.charAt(Math.floor(rng() * this.numbers.length));
      const randomLocation = Math.floor(rng() * input.length);
      return input.substring(0, randomLocation) + randomNumber + input.substring(randomLocation);
    },

    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    bindButtons: function() {
      var self = this;

      $("#generate-password").click(function() {
        self.generate();
      });

      $("#gen-copy").click(function() {
        self.copyToClipboard();
      });

      $("#gen-copy-and-deliver").click(function() {
        self.copyToClipboard();
        $("#secret-plaintext").val(self.$.generatedPassword.val());
      });
    },

    copyToClipboard: function() {
      this.clipBoard = new ClipboardJS('.copy-generated-password');

      this.clipBoard.on('success', function(e) {
        e.clearSelection();
      });
    },

    readValues: function() {
      this.numWords = $('input[name="gen-words-amount"]').val();
      this.wordSeparators = $('input[name="gen-word-separators"]').val();
      this.numPadding = $('input[name="gen-padding-amount"]').val();
      this.paddingChars = $('input[name="gen-padding-chars"]').val();
      this.randomNumbersNeeded = $('input[name="gen-random-numbers"]').prop('checked');
      this.capitalizeFirstLetterNeeded = $('input[name="gen-capitalize-first"]').prop('checked');

      this.storeValues(this.randomNumbersNeeded, this.capitalizeFirstLetterNeeded);
    },

    storeValues: function(numbers, capitalize) {
      try {
        const storeObj = {
          randomNumbersNeeded: numbers,
          capitalizeFirstLetterNeeded: capitalize,
        };

        localStorage.setItem('generatorValues', JSON.stringify(storeObj));
      } catch (error) {
        console.log('Unable to store values');
      }
    },

    readValuesFromLocalStorage: function() {
      try {
        const values = JSON.parse(localStorage.getItem('generatorValues'));

        if (!values) {
          return
        }

        $('input[name="gen-random-numbers"]').prop('checked', values.randomNumbersNeeded);
        $('input[name="gen-capitalize-first"]').prop('checked', values.capitalizeFirstLetterNeeded);
      } catch (error) {
        console.log('Unable to read values');
      }
    },

    rollDice: function() {
      let numbers = "";

      const rng = seedrandom();

      for (let i = 0; i < 5; i++) {
        numbers += Math.floor(rng() * 5) + 1;
      }

      return numbers;
    },

    resetPassword: function() {
      this.chosenWords = [];
      this.finalPassword = "";
    },

    generate: function() {
      this.resetPassword();
      this.readValues();

      const rng = seedrandom();

      for (let i = 0; i < this.numWords; i++) {
        this.chosenWords.push(win.app.eff_word_list[this.rollDice()]);
      }

      for (var i = 0; i < this.chosenWords.length; i++) {
        let word = this.chosenWords[i];

        if (this.capitalizeFirstLetterNeeded) {
          word = this.capitalizeFirstLetter(word);
        }

        this.finalPassword += word;

        if (this.wordSeparators != null && this.wordSeparators.length > 0 && i != this.chosenWords.length - 1) {
          this.finalPassword += this.wordSeparators.charAt(Math.floor(rng() * this.wordSeparators.length));
        }
      }

      if (this.numPadding != null && this.numPadding > 0) {
        if (this.paddingChars != null && this.paddingChars.length > 0) {
          let paddingChars1 = "";
          let paddingChars2 = "";

          for (let i = 0; i < this.numPadding; i++) {
            paddingChars1 += this.paddingChars.charAt(Math.floor(rng() * this.paddingChars.length));
            paddingChars2 += this.paddingChars.charAt(Math.floor(rng() * this.paddingChars.length));
          }

          if (this.randomNumbersNeeded) {
            paddingChars1 = this.insertRandomNumber(paddingChars1);
            paddingChars2 = this.insertRandomNumber(paddingChars2);
          }

          this.finalPassword = paddingChars1 + this.finalPassword + paddingChars2;
        }
      }

      this.$.generatedPassword.val(this.finalPassword);
    }

  };

  $(document).ready(function() {
    win.app.generatePassword.init();
  });

})(window);