import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";

Rails.start();
ActiveStorage.start();

import "stylesheets/application.scss";

import "packs/general";
import "packs/store_secret";
import "packs/tabs";
import "packs/view_secret";
import "packs/generate_password";

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// Disable Trix file uploads
document.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
});

Coloris({
  alpha: false
});
