import sjcl from './sjcl';
import seedrandom from 'seedrandom';
import ClipboardJS from 'clipboard';
import { encryptString } from './crypto';
import downloadFile from './downloader';

(function(win) {
  win.app = win.app || {};

  win.app.storeSecret = {
    $: {},

    init: function() {
      this.bindSubmit();
      this.bindFileUpload();
      this.bindDownloadQRCode();
      this.resetFields();
    },

    bindFileUpload: function() {
      const MAX_FILE_SIZE = 1024*1024*5;

      this.files = [];

      const self = this;

      $(document).on('change', '#add-secret-attachment input[type=file]', function(e) {
        e.preventDefault();

        const fileInput = e.target;

        if (fileInput.files.length < 1) {
          return;
        }

        const addedAttachmentsContainer = $('#added-secret-attachments');
        const addedAttachments = $('#added-secret-attachments-files');

        addedAttachmentsContainer.addClass('is-hidden');
        addedAttachments.html('');

        const file = fileInput.files[0];
        const fileName = file.name;
        const fileSize = file.size;

        if (fileSize > MAX_FILE_SIZE) {
          alert('The file size exceeds the maximum file size.');

          fileInput.files = null;

          return false;
        }

        addedAttachments.append('<li>' + fileName + ', ' + fileSize + ' bytes</li>');
        addedAttachmentsContainer.removeClass('is-hidden');

        const reader = new FileReader();

        reader.addEventListener('load', () => {
          self.files = [{ 'data': reader.result, 'name': fileName, 'size': fileSize }];
        }, false);

        if (file) {
          reader.readAsDataURL(file);
        }
      });
    },

    bindSubmit: function() {
      const self = this;

      $(document).on('click', '.create-link', async function(e) {
        e.preventDefault();

        self.secretPlaintext = $('#secret-plaintext').val();

        if (self.secretPlaintext === null || self.secretPlaintext === '') {
          return;
        }

        $(this).addClass('is-loading');

        self.disableFields();
        await self.storeSecret();
      });
    },

    bindDownloadQRCode: function() {
      const self = this;

      $(document).on('click', '#download-qr-code', async function(e) {
        e.preventDefault();

        const img = $('#qrcode > img')[0].src;

        await downloadFile(img, 'secret-qr-code.png');
      });
    },

    bindViewPlaintext: function() {
      const self = this;

      $(document).on('click', '.view-plaintext-secret', function(e) {
        e.preventDefault();

        $('#secret-stored-plaintext').text(self.secretPlaintext);
        $('#secret-stored-plaintext').removeClass('is-hidden');

        $(this).hide();
      });
    },

    bindViewPassword: function() {
      const self = this;

      if (!self.secretPassword) {
        $('.view-secret-password').prop('disabled', true);
      } else {
        $('.view-secret-password').prop('disabled', false);
      }

      $(document).on('click', '.view-secret-password', function(e) {
        e.preventDefault();

        if (!self.secretPassword) {
          return;
        }

        $('#secret-password-plaintext').text(self.secretPassword);
        $('#secret-password-plaintext').removeClass('is-hidden');

        $(this).hide();
      });
    },

    getDetailValues: function() {
      this.detailDescription = $('input[name="secret-description"]').val();
      this.detailMessage = $('input[name="secret-message"]').val();
      this.detailExpirationTime = $('input[name="secret-expiration"]').val();
      this.detailMaxViews = $('input[name="secret-maxviews"]').val();
      this.detailViewButton = $('input[name="secret-view-button"]').prop('checked');
      this.detailCaptcha = $('input[name="secret-captcha"]').prop('checked');
      this.secretPassword = $('input[name="secret-password"]').val();
      this.secretOtpRecipient = $('input[name="secret-otp-recipient"]').val();
      this.secretEmail = $('input[name="secret-email"]').val();
    },

    storeSecret: async function() {
      this.getDetailValues();

      try {
        this.passwordPartPrivate = this.generateString();
        this.passwordPartPublic = this.generateString();

        const ciphertext = btoa(sjcl.encrypt(
          this.passwordPartPrivate + this.passwordPartPublic,
          this.secretPlaintext,
          { 'mode': 'gcm', 'ks': 256, 'iter': 10000 }
        ));

        this.passwordPartPrivateB64 = btoa(this.passwordPartPrivate);
        this.passwordPartPublicB64 = btoa(this.passwordPartPublic);

        this.secretData = {
          ciphertext,
          password_part_private: this.passwordPartPrivateB64,
          description: this.detailDescription,
          message: this.detailMessage,
          expiration: this.detailExpirationTime,
          max_views: this.detailMaxViews,
          view_button: this.detailViewButton,
          captcha: this.detailCaptcha,
        };

        if (this.secretPassword) {
          this.secretData.password = this.secretPassword;
        }

        if (this.secretOtpRecipient) {
          this.secretData.otp_recipient = this.secretOtpRecipient;
        }

        if (this.secretEmail) {
          this.secretData.password_part_public = this.passwordPartPublicB64;
          this.secretData.email_to = this.secretEmail;
        }

        if (this.files && this.files.length) {
          this.secretData.attachment = {
            'file_name': this.files[0].name,
            'file_type': this.files[0].data.split(';')[0].split(':')[1],
            'file_size': this.files[0].size,
          };
        }
      }
      catch (e) {
        console.log(e);

        this.showError('Something went wrong. Please try again a bit later.');
        this.enableFields();

        return
      }

      this.disableFields();
      this.sendAjax();
    },

    enableCopyButton: function() {
      this.clipBoard = new ClipboardJS('.copy-secret');
    },

    disableFields: function() {
      $('#secret-plaintext').prop('readonly', true);
      $('.create-link').prop('disabled', true);
      $('.secret-detail').prop('readonly', true);
      $('.secret-detail').prop('disabled', true);
    },

    enableFields: function() {
      $('#secret-plaintext').prop('readonly', false);
      $('.create-link').removeClass('is-loading');
      $('.create-link').prop('disabled', false);
      $('.secret-detail').prop('readonly', false);
      $('.secret-detail').prop('disabled', false);
    },

    uploadFiles: async function() {
      if (!this.files || this.files.length < 1) {
        return
      }

      const file = this.files[0];
      const data = this.uploadData;

      const formData = new FormData();

      if (data.upload.metadata) {
        Object.entries(data.upload.metadata).forEach(([k, v]) => {
          formData.append(k, v);
        });
      }

      const encryptedFile = await encryptString(file.data, this.passwordPartPrivate + this.passwordPartPublic);

      const blob = new Blob([encryptedFile], { type: 'text/plain' });

      formData.append('file', blob, 'file.txt');

      const postResponse = await fetch(data.upload.url, {
        method: 'post',
        mode: 'no-cors',
        body: formData,
      });

      if (postResponse.status !== 0 && !postResponse.status.toString().startsWith('2')) {
        throw new Error('Upload failed, status code: ' + postResponse.status);
      }

      this.files = null;
    },

    sendAjax: function() {
      const self = this;

      $.ajax({
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        type: 'post',
        url: win.app.storeSecret.endpoint,
        data: self.secretData,
        dataType: 'json',
        success: async function(result) {
          if (result && result.attachment) {
            self.uploadData = result.attachment;

            try {
              await self.uploadFiles();
            }
            catch (e) {
              console.log(e);
              self.uploadError();
            }
            self.ajaxSuccess(result);
          }
          else {
            self.ajaxSuccess(result);
          }

          self.secretData = null;
        },
        error: function(jqxhr) {
          self.ajaxError(jqxhr);

          self.secretData = null;
        }
      });
    },

    ajaxSuccess: function(result) {
      this.secretId = result.id;
      this.secretToken = result.token;

      if (result.id && result.domain) {
        this.secretUrl = 'https://' + result.domain + '/' + result.id + '/#' + this.passwordPartPublicB64;

        $('#secret-link').val(this.secretUrl);

        if ($('#qrcode').length) {
          const qrcode = new QRCode("qrcode", {
            text: this.secretUrl,
            width: 128,
            height: 128,
            colorDark: "#000000",
            colorLight: "#ffffff"
          });
        }
      }

      this.showResult();
      this.enableCopyButton();
      this.bindViewPlaintext();
      this.bindViewPassword();

      $(document).trigger('linkCreated', [ this.secretUrl, this.secretToken ]);
    },

    uploadError: function() {
      $('#upload-error').removeClass('is-hidden');
    },

    ajaxError: function(jqxhr) {
      this.enableFields();

      let errorMessage = 'Something went wrong. Please try again a bit later.';

      if (jqxhr.responseText) {
        const errorObj = JSON.parse(jqxhr.responseText);

        if (errorObj.error) {
          errorMessage = errorObj.error;
        }

        if (errorMessage === 'E_LIMIT_REACHED') {
          return this.showLimitReachedError();
        }

        if (errorObj.field && errorObj.field === 'ciphertext' && errorObj.error.includes('is too long')) {
          return this.showCiphertextError(errorObj.field);
        }

        if (errorObj.field && errorObj.field !== '') {
          return this.showDetailError(errorObj.field);
        }
      }

      this.showError(errorMessage);
    },

    showResult: function() {
      $('.the-secret-id').text(this.secretId);
      $('.the-secret-description').text(this.detailDescription ? this.detailDescription : 'Not set');
      $('.the-secret-message').text(this.detailMessage ? this.detailMessage : 'Not set');
      $('.the-secret-expiration').text(this.detailExpirationTime ? 'Expires in ' + this.detailExpirationTime + ' hours' : 'Not set');
      $('.the-secret-max-views').text(this.detailMaxViews ? this.detailMaxViews : '1');
      $('.the-secret-view-button').text(this.detailViewButton ? 'Enabled' : 'Disabled');
      $('.the-secret-has-password').text(this.secretPassword ? 'Enabled' : 'Disabled');
      $('.the-secret-has-captcha').text(this.detailCaptcha ? 'Enabled' : 'Disabled');

      $('#new-secret').addClass('is-hidden');
      $('#stored-secret').removeClass('is-hidden');
      $('#secret-details').removeClass('is-hidden');

      $(window).scrollTop(0);
    },

    showError: function(message) {
      $('.error-text').text(message);
      $('.secret-error').removeClass('is-hidden');
    },

    showLimitReachedError: function() {
      $('.storage-limit-reached').removeClass('is-hidden');
    },

    showDetailError: function(field) {
      if (field === 'message') {
        win.app.tabsToggler.switchToTab('new-secret', 'settings');
        $('.error-message').show();
        $('input[name="secret-message"]').addClass('input-error');
      }

      if (field === 'description') {
        win.app.tabsToggler.switchToTab('new-secret', 'settings');
        $('.error-description').show();
        $('input[name="secret-description"]').addClass('input-error');
      }

      if (field === 'expiration') {
        win.app.tabsToggler.switchToTab('new-secret', 'settings');
        $('.error-expiration').show();
        $('input[name="secret-expiration"]').addClass('input-error');
      }

      if (field === 'max_views') {
        win.app.tabsToggler.switchToTab('new-secret', 'settings');
        $('.error-maxviews').show();
        $('input[name="secret-maxviews"]').addClass('input-error');
      }

      if (field === 'password') {
        win.app.tabsToggler.switchToTab('new-secret', 'password');
        $('.error-password').show();
        $('input[name="secret-password"]').addClass('input-error');
      }

      if (field === 'otp_recipient') {
        win.app.tabsToggler.switchToTab('new-secret', 'otp');
        $('.error-password').show();
        $('input[name="secret-otp-recipient"]').addClass('input-error');
      }
    },

    showCiphertextError: function() {
      $('.error-plaintext').show();
      $('#secret-plaintext').removeClass('is-success');
      $('#secret-plaintext').addClass('is-danger');
    },

    clearDetailErrors: function() {
      $('.detail-error').hide();
      $('input').removeClass('input-error');
    },

    resetFields: function() {
      const defaultSecret = $('#secret-plaintext').data('default-value');
      const defaultMessage = $('input[name="secret-message"]').data('default-value');
      const defaultDescription = $('input[name="secret-description"]').data('default-value');
      const defaultExpiration = $('input[name="secret-expiration"]').data('default-value');
      const defaultMaxViews = $('input[name="secret-maxviews"]').data('default-value');
      const defaultViewButton = $('input[name="secret-view-button"]').data('default-value');
      const defaultCaptcha = $('input[name="secret-captcha"]').data('default-value');
      const defaultPassword = $('input[name="secret-password"]').data('default-value');

      $('#secret-plaintext').val(defaultSecret);
      $('input[name="secret-message"]').val(defaultMessage);
      $('input[name="secret-description"]').val(defaultDescription);
      $('input[name="secret-expiration"]').val(defaultExpiration);
      $('input[name="secret-maxviews"]').val(defaultMaxViews);
      $('input[name="secret-password"]').val(defaultPassword);

      if (defaultViewButton === 'checked') {
        $('input[name="secret-view-button"]').prop('checked', true);
      }

      if (defaultCaptcha === 'checked') {
        $('input[name="secret-captcha"]').prop('checked', true);
      }

      $('.secret-error').addClass('is-hidden');

      $('#secret-plaintext').focus();
    },

    generateString: function() {
      const len = 18;
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$|/\!_+,.-?()[]{}<>&#^*=@";

      const rng = seedrandom();

      let str = '';

      for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(rng() * chars.length));
      }

      return str;
    },
  };

  $(document).ready(function() {
    win.app.storeSecret.init();
  });

})(window);