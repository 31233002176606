const generateIv = () => {
  return window.crypto.getRandomValues(new Uint8Array(12));
};

const encode = (data) => {
  const encoder = new TextEncoder();
  return encoder.encode(data);
};

const decode = (bytestream) => {
  const decoder = new TextDecoder();
  return decoder.decode(bytestream);
};

const bufferToString = (buffer) => {
  let str = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }

  return str;
};

const stringToBuffer = (string) => {
  const buffer = new ArrayBuffer(string.length);
  const bufferView = new Uint8Array(buffer);

  for (let i = 0; i < string.length; i++) {
    bufferView[i] = string.charCodeAt(i);
  }

  return buffer;
};

const encrypt = async (data, key) => {
  const encoded = encode(data);
  const iv = generateIv();
  const cipher = await window.crypto.subtle.encrypt({
    name: 'AES-GCM',
    iv: iv,
  }, key, encoded);

  return {
    cipher,
    iv,
  }
};

const decrypt = async (cipher, iv, key) => {
  const encoded = await window.crypto.subtle.decrypt({
    name: 'AES-GCM',
    iv: iv,
  }, key, cipher);

  return decode(encoded);
};

const keyFromPassword = async (password, salt) => {
  const key = await window.crypto.subtle.importKey(
    'raw',
    encode(password),
    'PBKDF2',
    false,
    ['deriveBits', 'deriveKey'],
  );

  return await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt,
      iterations: 10000,
      hash: 'SHA-256',
    },
    key,
    { 'name': 'AES-GCM', 'length': 256 },
    true,
    ['encrypt', 'decrypt'],
  );
};

export const encryptString = async (string, password) => {
  const salt = window.crypto.getRandomValues(new Uint8Array(16));
  const key = await keyFromPassword(password, salt);
  const { cipher, iv } = await encrypt(string, key);

  const json = JSON.stringify({
    cipher: bufferToString(cipher),
    iv: bufferToString(iv),
    salt: bufferToString(salt),
  });

  return window.btoa(json);
};

export const decryptString = async (string, password) => {
  const json = window.atob(string);
  const array = JSON.parse(json);

  const key = await keyFromPassword(password, stringToBuffer(array.salt));

  return decrypt(stringToBuffer(array.cipher), stringToBuffer(array.iv), key);
};
