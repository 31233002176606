import Cookies from 'js-cookie';

(function(win) {
  win.app = win.app || {};

  win.app.tabsToggler = {
    $: {},

    init: function() {
      this.switchToLastActiveTab();
      this.bindTabClick();
    },

    switchToLastActiveTab: function() {
      try {
        const cookie = Cookies.get('active_tab');

        if (!cookie) {
          return
        }

        const activeTab = JSON.parse(cookie);

        if (activeTab) {
          this.switchToTab(activeTab.tabId, activeTab.tabName);
        }
      } catch (error) {
        console.log('Unable to switch to last active tab');
      }
    },

    setLastActiveTab: function(tabId, tabName) {
      try {
        const tabObj = { tabId: tabId, tabName: tabName };

        Cookies.set('active_tab', JSON.stringify(tabObj), { secure: true });
      } catch (error) {
        console.log('Unable to set last active tab');
      }
    },

    switchToTab: function(tabId, tabName) {
      $(".tabs-link[data-tab-id='" + tabId + "']").removeClass('is-active');
      $(".tabs-link[data-tab-name='" + tabName + "']").addClass('is-active');
      $("li[data-tab-id='" + tabId + "']").removeClass('is-active');
      $("li[data-tab-name='" + tabName + "']").addClass('is-active');
      $("div[data-tab-id='" + tabId +"']").addClass('is-hidden');
      $("div[data-tab-name='" + tabName +"']").removeClass('is-hidden');

      this.setLastActiveTab(tabId, tabName);
    },

    bindTabClick: function() {
      let self = this;

      $(document).on('click', '.tabs-link', function(e) {
        e.preventDefault();

        let tabId = $(this).data("tabId");
        let tabName = $(this).data("tabName");

        self.switchToTab(tabId, tabName);
      });
    }

  };

  $(document).ready(function() {
    win.app.tabsToggler.init();
  });

})(window);