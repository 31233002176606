import ClipboardJS from 'clipboard';

$(document).ready(function() {
  $("button.delete").click(function() {
    $(".notification-wrapper").hide();
  });

  $("button.auth-close").click(function() {
    $(".notification").hide();
  });

  $("a[data-member-id]").on("ajax:success", function() {
    var id = $(this).data("member-id");
    $("tr[data-member-id='" + id + "']").css("background", "#ffeeee").fadeOut("fast");
  });

  $("a[data-invite-id]").on("ajax:success", function() {
    var id = $(this).data("invite-id");
    $("tr[data-invite-id='" + id + "']").css("background", "#ffeeee").fadeOut("fast");
  });

  $("a[data-secret-id]").on("ajax:success", function() {
    var id = $(this).data("secret-id");
    $("tr[data-secret-id='" + id + "']").css("background", "#ffeeee").fadeOut("fast");
  });

  $("a[data-api-key-id]").on("ajax:success", function() {
    var id = $(this).data("api-key-id");
    $("tr[data-api-key-row='" + id + "']").css("background", "#ffeeee").fadeOut("fast");
  });

  $("a[data-secret-request-id]").on("ajax:success", function() {
    var id = $(this).data("secret-request-id");
    $("tr[data-secret-request-id='" + id + "']").css("background", "#ffeeee").fadeOut("fast");
  });

  $(".navbar-burger").click(function() {
    $(".navbar-menu").toggle("is-active");
  });

  $(".modal-close").on("click", function() {
    $(".modal").removeClass("is-active");
  });

  $(".modal-background").on("click", function() {
    $(".modal").removeClass("is-active");
  });

  $(document).keydown(function(event) {
    if (event.keyCode === 27) {
      $(".modal").removeClass("is-active");
    }
  });

  new ClipboardJS('.copy-button');
});
