import ClipboardJS from 'clipboard';
import sjcl from './sjcl';
import downloadFile from './downloader';
import { decryptString } from './crypto';

(function(win) {
  const setSecretError = () => {
    $('.something-went-wrong').removeClass('is-hidden');
    $('.plaintext-secret-wrapper').addClass('is-hidden');
  }

  const sjclDecrypt = (passwordPartPrivate, ciphertext) => {
    const passwordPartPublic = location.hash.substring(1);
    return sjcl.decrypt(atob(passwordPartPrivate) + atob(passwordPartPublic), atob(ciphertext));
  };

  const decryptSecret = (passwordPartPrivate, ciphertext) => {
    try {
      document.getElementById('view-plaintext').textContent = sjclDecrypt(passwordPartPrivate, ciphertext);
    }
    catch (e) {
      console.log(e);
      setSecretError();
    }
  }

  const showSecret = (ciphertext, passwordPartPrivate) => {
    if (!ciphertext || !passwordPartPrivate) {
      setSecretError();
    }

    decryptSecret(passwordPartPrivate, ciphertext);
  }

  const requestOtp = (id) => {
    $.ajax({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'));
      },
      type: 'post',
      url: '/' + id + '/send_otp',
      dataType: 'json',
      success: function(result, textStatus, xhr) {
        $('#request-otp').addClass("is-hidden");
        $('#otp-requested').removeClass("is-hidden");
      },
      error: function(xhr) {
        alert("Unable to request OTP. Please try again later.");
      }
    });
  }

  const getSecretViaAjax = (id) => {
    const secretPassword = $('.secret-password').val();
    const secretCaptcha = $('.secret-captcha').val();
    const secretOtp = $('.secret-otp').val();

    const data = {};

    if (secretPassword) {
      data.password = secretPassword;
    }

    if (secretCaptcha) {
      data.captcha = secretCaptcha;
    }

    if (secretOtp) {
      data.otp = secretOtp;
    }

    $.ajax({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'));
      },
      type: 'post',
      url: '/' + id + '/get',
      data: data,
      dataType: 'json',
      success: function(result, textStatus, xhr) {
        $('.button-wrapper').remove();
        $('.secret-container').removeClass('is-hidden');
        showSecret(result.ciphertext, result.password_part_private);

        if (result.attachments.length) {
          handleSecretAttachments(id, result.attachments, result.password_part_private);
        }
      },
      error: function(xhr) {
        if (xhr.status === 429) {
          return alert("Too many attempts. Please try again later.");
        }

        if (xhr.status === 403) {
          if (win.app.viewSecret.hasCaptcha) {
            showInvalidCaptcha();
          }
          else if (win.app.viewSecret.hasOtp) {
            showInvalidOtp();
          }
          else {
            showInvalidPassword();
          }
        } else {
          setSecretError();
        }
      }
    });
  }

  const handleSecretAttachments = (id, attachments, passwordPartPrivate) => {
    if (!attachments || !attachments[0]) {
      return
    }

    const container = $('.secret-attachments');

    const attachmentId = attachments[0].id;
    const fileName = attachments[0].file_name;
    const fileSize = attachments[0].file_size;

    container.append('<h4 class="title mt-4 mb-2">Attachment</h4>');
    container.append('<p>This secret has the following attachment: ' + fileName + ' (' + fileSize + ' bytes)</p>');
    container.append('<button class="button download-attachment" data-attachment-id="' + attachmentId + '">Download attachment</button>');

    container.removeClass('is-hidden');

    $(container).on('click', '.download-attachment', function() {
      $('.download-attachment').addClass('is-loading');

      let fileData;

      $.post('/' + id + '/attachment/' + attachmentId, async function(attachment) {
        try {
          const passwordPartPublic = location.hash.substring(1);
          const password = atob(passwordPartPrivate) + atob(passwordPartPublic);

          if (attachment.url) {
            $.get(attachment.url, async function (data) {
              fileData = data;

              await downloadAttachment(fileName, fileData, password);
            }).fail(function() {
              alert('Failed to download attachment.');
              $('.download-attachment').removeClass('is-loading');
            });
          } else {
            fileData = attachment.data;

            await downloadAttachment(fileName, fileData, password);
          }
        }
        catch (e) {
          console.log(e);
          alert('Failed to download attachment.');
          $('.download-attachment').removeClass('is-loading');
        }
      }).fail(function() {
          alert('Failed to download attachment.');
        $('.download-attachment').removeClass('is-loading');
      });
    });
  };

  const downloadAttachment = async (fileName, fileData, password) => {
    try {
      const result = await decryptString(fileData, password);
      await downloadFile(result, fileName);
      $('.download-attachment').removeClass('is-loading');
    }
    catch (e) {
      console.log('Failed to download attachment');
      console.log(e);
    }
  };

  const showInvalidPassword = () => {
    $('#invalid-secret-password').removeClass('is-hidden');
    $('#view-secret-button').removeClass('is-loading');
  }

  const showInvalidCaptcha = () => {
    $('#invalid-secret-captcha').removeClass('is-hidden');
    $('#view-secret-button').removeClass('is-loading');
  }

  const showInvalidOtp = () => {
    $('#invalid-secret-otp').removeClass('is-hidden');
    $('#view-secret-button').removeClass('is-loading');
  }

  const enableViewSecretButton = () => {
    $('#view-secret-button').prop('disabled', false);
  };

  $(document).ready(function() {
    if (!win.app || !win.app.viewSecret) {
      return
    }

    setTimeout(enableViewSecretButton, 2000);

    if (win.app.viewSecret.inlineSecret) {
      decryptSecret(win.app.viewSecret.passwordPartPrivate, win.app.viewSecret.ciphertext);
      handleSecretAttachments(win.app.viewSecret.secretId,
        JSON.parse(win.app.viewSecret.attachments.replace(/&quot;/g,'"') || "{}"),
        win.app.viewSecret.passwordPartPrivate);
    }

    $('#request-otp-button').on('click', function() {
      $('#request-secret-button').prop('disabled', true);
      $(this).addClass('is-loading');

      requestOtp(win.app.viewSecret.secretId);
    });

    $('#view-secret-button').on('click', function() {
      $('#view-secret-button').prop('disabled', true);
      $(this).addClass('is-loading');

      getSecretViaAjax(win.app.viewSecret.secretId);
    });

    new ClipboardJS('.plaintext-secret-copy-button');
  });

})(window);